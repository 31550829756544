
import { getUrlWithFormat } from "@/utils/formatImgUrl";

export default {
  name: "LastArticle",
  components: {
    ButtonPlay: () => import("@/components/buttons/ButtonPlay"),
    Link: () => import("@/components/elements/Link.vue"),
  },
  props: ["item"],
  methods: {
    getUrlWithFormat,
  },
  computed: {
    isObfuscated() {
      if(this.item.type) {
        switch (this.item.type) {
          case 'show':
            return false
          case 'article':
            return false
          case 'episode':
            return true
          default:
            return true
        }
      }
    },
  },
};
